<template>
  <div>
    <div class="popupTitle">药品用量</div>
    <van-field
        class="medicine-name-field"
        readonly clickable :value="medicineName" label="药品名称" placeholder="点击选择"
               @click="medicinePopup = true"/>

    <van-field label="每日" label-width="auto" center :rules="[{ required: true }]">
      <template #input>
        <van-stepper v-model="medicine.timesPerDay" min="0" max="23" integer style="margin-right: 10px"/>
        次
      </template>
    </van-field>
    <van-field label="每次" label-width="auto" center :rules="[{ required: true }]">
      <template #input>
        <van-stepper v-model="medicine.dosePerDay" min="0" max="23" integer style="margin-right: 10px"/>
        粒（包，片）
      </template>
    </van-field>
    <div class="buttons">
      <van-button size="small" color="var(--mainColor)" plain native-type="button" @click="$emit('close')">取消
      </van-button>
      <van-button size="small" color="var(--mainColor)" native-type="button" @click="confirm">确认</van-button>
    </div>

    <!-- 药品列表 -->
    <van-popup v-model="medicinePopup" position="bottom" get-container="body">
      <van-picker show-toolbar title="药品列表" :columns="medicineList" @confirm="onConfirm"
                  @cancel="medicinePopup = false"/>
    </van-popup>

  </div>
</template>

<script>
export default {
  props: {
    medicineList: {
      type: Array,
      default: _ => []
    },
    medicineInfo: {
      type: Object,
      default: _ => {
      }
    }
  },
  data() {
    return {
      medicinePopup: false,
      medicine: {}
    };
  },
  watch: {
    medicineInfo: {
      deep: true,
      immediate: true,
      handler(e) {
        this.medicine = JSON.parse(JSON.stringify(e))
      }
    }
  },
  computed: {
    medicineName() {
      if (!this.medicine.medicineId) return ''
      const list = this.medicineList
      for (let i = 0; i < list.length; i++) {
        if (this.medicine.medicineId == list[i].value) return list[i].text
      }
      return ''
    }
  },
  methods: {
    // 选择药品
    onConfirm(e) {
      this.medicine.medicineId = e.value
      this.medicinePopup = false
    },

    // 完成剂量填写
    confirm() {
      if (!this.medicine.medicineId) return this.$toast('请选择药品')
      this.$emit('submit', this.medicine)
    }
  },
};
</script>

<style scoped lang="less">
.popupTitle {
  text-align: center;
  padding: 10px;
  font-size: 16px;
}

.buttons {
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 10px 30px;
}

/deep/ .medicine-name-field {
  .van-field__value {
    margin-top: 0;
  }
}
</style>
