<template>
  <div class="other" style="padding-bottom: 2rem">
    <van-cell-group inset :border="false">
      <template v-for="(item, index) in textareaList">
        <van-cell
            :key="item.key"
            class="pb0"
            :title="item.label"
            :border="false"
        ></van-cell>
        <van-field
            :key="index"
            class="pt0"
            type="textarea"
            maxlength="999"
            show-word-limit
            v-model="_clientOther[item.key]"
            :autosize="{ maxHeight: 80, minHeight: 40 }"
            :placeholder="item.placeholder"
            :readonly="isReadonlySelf"
        />
      </template>
    </van-cell-group>
  </div>
</template>

<script>
import {saveOther, getOther} from "@/api/hoss";
import mixin from "./mixin.js";

export default {
  // mixins: [mixin],
  props: {
    clientId: {
      type: String,
      default: "",
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    clientOther: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      textareaList: [
        {
          key: "dreams",
          label: "有什么梦想，想做的事，曾经想过去做的事，做过什么印象深刻的事？",
          placeholder: "请输入",
        },
        {
          key: "placesToVisit",
          label: "想要去的地方，想要旅游的城市，去过哪些城市和地方，对户外有兴趣吗，为什么？",
          placeholder: "请输入",
        },
        {
          key: "interests",
          label: "爱吃的东西，爱玩的事情，以前有过什么爱好，喜欢什么运动或活动？",
          placeholder: "请输入",
        },
        {
          key: "impressive",
          label: "讨厌的事，反感的事，害怕的事，受过伤的事，感受到快乐的事，印象深刻的事？",
          placeholder: "请输入",
        },
        {
          key: "recreations",
          label: "爱看的综艺节目，爱看的电视剧，喜欢的影星，歌星，歌曲，网红，小说，等等？",
          placeholder: "请输入",
        },
        {
          key: "pastCharacteristics",
          label: "曾经的心态，性格，说话方式，做事方式，为人处世方式，对亲人的态度，对朋友的态度？",
          placeholder: "请输入",
        },
        {
          key: "currentCharacteristics",
          label: "目前的心态，性格，说话方式，做事方式，为人处世方式，对亲人的态度，对朋友的态度？",
          placeholder: "请输入",
        },
        {
          key: "sensitives",
          label: "什么事会容易触发抵触，什么事会容易触发愉快？",
          placeholder: "请输入",
        },
      ],
      refName: "other",
      isReadonlySelf: false,
      formData: {
        dreams: "",
        placesToVisit: "",
        interests: "",
        impressive: "",
        recreations: "",
        pastCharacteristics: "",
        currentCharacteristics: "",
        sensitives: "",
        clientId: this.clientId,
        state: 0,
      },
    };
  },
  computed: {
    _clientOther: {
      get() {
        return this.clientOther;
      },
      set(val) {
        this.$emit("update:clientOther", val);
      }
    }
  },
  async created() {
    console.log("other 初始化，isReadonly: ", this.isReadonly);
    this.isReadonlySelf = this.isReadonly;
    // if (this.clientId) {
    //   this.$toast.loading();
    //   await this.queryOther();
    //   this.$toast.clear();
    // }
  },
  watch: {
    isReadonly(val) {
      console.log("other isReadonly监听到发送变化，由父传入: ", val);
      this.isReadonlySelf = val;
    },
    isReadonlySelf(val) {
      console.log("other isReadonlySelf监听到发送变化，由自身变化: ", val);
      this.$emit("onReadonlyChange", val);
    },
  },
  methods: {
    onRefIsUploadingAddIntoList() {
      this.$emit("refIsUploadingAddIntoList", this.refName);
    }
    ,
    // async saveData(e) {
    //   this.formData.clientId = this.clientId;
    //   if (this.formData.clientId) {
    //     if (this.formData.state !== 1) this.formData.state = e;
    //     await saveOther(this.formData);
    //     this.$emit("onSaveSuccess", "other");
    //     // if (e) await this.$router.push(`/hoss/clientList?refresh=true`);
    //   } else {
    //     this.$emit("needSaveButClientIdIsNotReady", "other");
    //   }
    // }
    // ,
    // async save() {
    //   await this.saveData(0);
    // }
    // ,
    // async queryOther() {
    //   const {value} = await getOther(this.clientId);
    //   this.setState(value);
    //   this.formData = {...this.formData, ...value};
    // }
    // ,
    validator() {
      return true;
    }
    ,
  }
  ,
}
;
</script>

<style scoped lang="less">
.pb0 {
  padding-bottom: 0;
}

.pt0 {
  padding-top: 0;
}

/deep/ .van-cell__value {
  background-color: var(--secondCardBackgroundColor);
  padding: 4px;
  border-radius: 4px;
}

.other {
  margin-top: 10px;
}

.van-cell__title {
  color: var(--titleColor);
  font-size: 14px;
  letter-spacing: -0.7px;
}

/deep/ .van-field__value {
  padding: 3px 8px 5px;
  border-radius: 10px;
  margin-top: 6px;
  background-color: var(--separatorColor);
}

/deep/ .van-cell::after {
  display: none;
}

/deep/ .pb0 {
  padding-top: 0.05rem;
}

/deep/ .pb0:first-child {
  padding-top: 0.3rem;
}

/deep/ .pt0:last-child {
  padding-bottom: 0.5rem;
}


// textarea 占位文字

/deep/ textarea {
  font-size: 13px;
  // 修改占位符
  &::placeholder {
    color: var(--subTitleColor);
    font-weight: normal;
  }
}

/deep/ .van-field__word-limit {
  font-size: 13px;
  color: var(--subTitleColor);
}

/deep/ .van-field__word-num {
  font-size: 13px;
}

</style>
