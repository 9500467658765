<template>
  <div :class="`checkbox-${type}-group checkbox-group`" :style="colorStyles">
    <label
        v-if="indeterminate"
        :for="'checkAll' + groupKey"
        :class="['checkbox checkbox-checkAll', { 'checkbox-isActive': checkAll }]"
    >
      <input
          type="checkbox"
          :id="'checkAll' + groupKey"
          :disabled="disabled"
          :value="true"
          v-model="checkAll"
      />
      <span>全选</span>
    </label>
<!--    {{ $value }}-->
    <label
        v-for="item in list"
        :key="item[customKey]"
        :class="['checkbox', { 'checkbox-isActive': $value.includes(item[customKey]) }]"
        :for="`option${item.id}`"
    >
      <input
          v-model="$value"
          type="checkbox"
          :id="`option${item.id}`"
          :value="item[customKey]"
          :disabled="disabled"
          @click="$emit('click-option', { item, isSelect: $value.includes(item[customKey]) })"
      />
      <span v-if="type == 'button'" class="checkbox__icon"></span>
      <span class="checkbox__inner">{{ item.name }}</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    customKey: {
      type: String,
      default: "code",
    },
    groupKey: {
      type: String,
      default: "",
    },
    list: {
      // 选项列表 需要包含[customKey], name
      type: Array,
      default: () => [],
    },
    value: {
      // 绑定值，支持v-model双向绑定
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
    },
    indeterminate: {
      // 是否需要全选的中间状态 item.[customKey]不能是checkAll，checkAll是中间状态的[customKey]
      type: [String, Boolean],
      default: true,
    },
    type: {
      // 分为标签样式tag（面板下方标签）  按钮样式button（筛选面板）
      type: String,
      default: "button",
    },
    disabled: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      colorStyles: {},
    };
  },
  computed: {
    $value: {
      get() {
        return JSON.parse(JSON.stringify(this.value));
      },
      set(val) {
        // 删掉代表全选的中间状态
        const checked = val.filter((item) => {
          return item != "checkAll";
        });
        this.$emit("input", checked);
      },
    },
    checkAll: {
      get() {
        return this.list.length == this.$value.length;
      },
      set(val) {
        if (val) {
          this.$value = this.list.map((item) => item[this.customKey]);
        } else {
          this.$value = [];
        }
      },
    },
  },
  watch: {
    $theme: {
      handler(theme) {
        if (theme == "SUN_MODE") {
          this.colorStyles = {
            "--color": "#205af9",
            "--background": "#d3dbff",
          };
        } else {
          this.colorStyles = {
            "--color": "#e14440",
            "--background": "#703325",
          };
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="less">
.checkbox-group {


  .checkbox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    box-sizing: border-box;

    input[type="checkbox"] {
      display: none !important;
    }
  }

  &.checkbox-button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .checkbox-checkAll.checkbox-isActive {
      border: 1px solid var(--color) !important;
      background: transparent !important;
      color: var(--color) !important;
    }

    .checkbox {
      flex: 0 0 48%;
      height: 40px;
      margin-bottom: 10px;
      transition: all 0.14s;
      border: 1px solid var(--separatorColor);

      .checkbox__icon {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--color);
        margin-right: 10px;
      }

      &-isActive {
        border: none;
        background-color: transparent;
      }
    }
  }

  &.checkbox-tag-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    margin-bottom: -5px;

    .checkbox {
      flex: 0 0 30%;
      font-size: 12px;
      padding: 6px 0;
      margin-bottom: 10px;
      //margin: 5px 8px 5px 0;
      background-color: transparent;
      border: 1px solid var(--color);

      &-isActive {
        background-color: var(--background);
        border-color: transparent;
      }
    }
  }
}
</style>
