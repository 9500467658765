var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-customer"},[_c('van-tabs',{ref:"tabs",attrs:{"swipeable":"","lazy-render":false},on:{"change":function($event){return _vm.onTabChange()}},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tab',{attrs:{"title":"基本信息","title-class":_vm.validatorFailedList.includes(0)
             ? 'tab-title-error'
             : 'tab-title'}},[_c('basic-info',{ref:"basicInfo",attrs:{"is-readonly":_vm.isReadonly,"basic-info":_vm.clientFile.basicInfo},on:{"changeSaveStatus":_vm.changeHpTitleBarSaveComponentStatus,"tab-change":_vm.tabChange,"update:basicInfo":function($event){return _vm.$set(_vm.clientFile, "basicInfo", $event)},"update:basic-info":function($event){return _vm.$set(_vm.clientFile, "basicInfo", $event)}}})],1),_c('van-tab',{attrs:{"title":"诊断情况","title-class":_vm.validatorFailedList.includes(1)
             ? 'tab-title-error'
             : 'tab-title'}},[_c('diagnose',{ref:"diagnose",attrs:{"is-readonly":_vm.isReadonly,"diagnose":_vm.clientFile.diagnosis},on:{"tab-change":_vm.tabChange,"changeSaveStatus":_vm.changeHpTitleBarSaveComponentStatus,"update:diagnose":function($event){return _vm.$set(_vm.clientFile, "diagnosis", $event)}}})],1),_c('van-tab',{attrs:{"title":"睡眠情况","title-class":_vm.validatorFailedList.includes(2)
             ? 'tab-title-error'
             : 'tab-title'}},[_c('sleep',{ref:"sleep",attrs:{"is-readonly":_vm.isReadonly,"sleep":_vm.clientFile.sleep},on:{"tab-change":_vm.tabChange,"changeSaveStatus":_vm.changeHpTitleBarSaveComponentStatus,"update:sleep":function($event){return _vm.$set(_vm.clientFile, "sleep", $event)}}})],1),_c('van-tab',{attrs:{"title":"其他信息","title-class":_vm.validatorFailedList.includes(3)
             ? 'tab-title-error'
             : 'tab-title'}},[_c('other',{ref:"other",attrs:{"is-readonly":_vm.isReadonly,"client-other":_vm.clientFile.clientOther},on:{"tab-change":_vm.tabChange,"changeSaveStatus":_vm.changeHpTitleBarSaveComponentStatus,"update:clientOther":function($event){return _vm.$set(_vm.clientFile, "clientOther", $event)},"update:client-other":function($event){return _vm.$set(_vm.clientFile, "clientOther", $event)}}})],1)],1),(!_vm.isReadonly)?_c('div',{staticClass:"hover-commit",on:{"click":_vm.onSubmit}},[_vm._v(" 提交 ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }