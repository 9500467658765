<template>
  <div class="quick-select-edit-input"
       :class="{
    'left':align === 'left',
    'right':align === 'right'
  }"
  >
    <template v-if="showEditInput">
      <van-field
          v-if="type === 'text'"
          v-model="formData[targetKey]"
          :rules="rules"
          :placeholder="placeholder"
          :readonly="isReadonly"
      ></van-field>
    </template>
    <div class="quick-select-options-group">
      <div v-for="(item,index) in quickSelectOptions" :key="index" class="quick-select-option"
           :class="{'active': isActive(item.targetValue)}" @click="handleClick(item.targetValue)">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "QuickSelectEditInput",
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    targetKey: {
      type: String,
      default: "",
    },
    quickSelectOptions: {
      type: Array,
      default: () => [],
    },
    // 是否展示输入框
    showEditInput: {
      type: Boolean,
      default: false,
    },
    // 类型是文本还是数组
    type: {
      type: String,
      default: "text",
    },
    // 单选还是多选
    multiple: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: "left",
    },
    //rules
    rules: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  computed: {
    _formData: {
      get() {
        return this.formData;
      },
      set(value) {
        this.$emit("changeForm", value);
      },
    },
  },
  methods: {
    // 点击选项
    handleClick(targetValue) {
      if (this.isReadonly) {
        return;
      }
      console.log(targetValue);
      if (this.multiple) {
        // 多选
        if (this.formData[this.targetKey].includes(targetValue)) {
          // 如果已经选中了，就取消选中
          this.formData[this.targetKey] = this.formData[this.targetKey].filter(
              (item) => item !== targetValue
          );
        } else {
          // 如果没有选中，就选中
          this.formData[this.targetKey].push(targetValue);
        }
      } else {
        // 单选
        this.formData[this.targetKey] = targetValue;
        // 设置formData
        this._formData = Object.assign({}, this.formData);
        console.log("单选", this.formData[this.targetKey]);
        console.log("单选-目标key", this.targetKey);
      }
    },
    // 判断是否选中
    isActive(targetValue) {
      const targetKeyValue = this.formData[this.targetKey];
      if (this.multiple) {
        // 多选
        return targetKeyValue.includes(targetValue);
      } else {
        // 单选
        return targetKeyValue === targetValue;
      }
    },
  },
}
</script>

<style scoped lang="less">
.quick-select-edit-input {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.left {
    // 居左
    justify-content: flex-start;

    .quick-select-options-group {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: -0.2rem;
    }

    .quick-select-option {
      margin-right: 0.5rem;
    }

    // 每三个一行
    .quick-select-option:nth-child(3n) {
      margin-right: 0;
    }

  }

  &.right {
    // 居右
    justify-content: flex-end;

    .quick-select-options-group {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      margin-bottom: -0.2rem;
    }

    .quick-select-option {
      margin-left: 0.5rem;
    }

    // 每三个一行
    .quick-select-option:nth-child(3n) {
      margin-right: 0;
    }

  }

  .quick-select-option {
    margin-bottom: 0.2rem;
    text-align: center;
    font-size: 11px;
    background-color: var(--buttonSelectColor);
    color: #000000;
    border-radius: 1rem;
    padding: 0 0.1rem;
    width: 25%;

    &.active {
      background-color: var(--mainColor);
      color: #ffffff;
    }

  }

  .van-field {
    padding: 0;
    margin-bottom: 0.1rem;
  }

  .van-cell::after {
    border: none;
  }
}

</style>
