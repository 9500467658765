<template>
  <div class="form-box" style="padding-bottom: 2rem">
    <!-- 标签 -->
    <van-cell-group :border="false" inset style="margin-top: 10px;padding-left: 2px;padding-right: 2px;">
      <div style="margin: 10px">
        <div class="symptom-title" style="
        margin-bottom: 10px;
        font-size: 14px;"
        >
          症状
        </div>
        <checkout-group
            class="checkout-group-container"
            v-model="symptoms"
            :list="symptomsList"
            :indeterminate="false"
            :disabled="isReadonly"
            type="tag"
            @input="_diagnose.symptoms = $event.join()"
        />
      </div>
      <!-- 表单 -->
      <!--      <van-cell-group :border="false" inset title="基本情况" class="basicInfo">-->
      <div class="card">
        <template v-for="(item, index) in textareaList">
          <van-cell
              :key="item.key"
              class="pb0"
              :title="item.label"
              :border="false"
              required
          ></van-cell>
          <van-field
              :key="index"
              class="pt0"
              type="textarea"
              maxlength="999"
              show-word-limit
              v-model="_diagnose[item.key]"
              :readonly="isReadonly"
              :autosize="{ maxHeight: 80, minHeight: 40 }"
              :placeholder="item.placeholder"
          />
        </template>
      </div>
      <!--      </van-cell-group>-->
      <!-- 列表 -->
      <div style="font-size: 14px;margin: 10px;">
        服用药品名称及用量
      </div>
      <van-cell
          :title="item.medicineId | medicineName(medicineList)"
          :label="'每日' + item.timesPerDay + '次，每次' + item.dosePerDay + '粒'"
          v-for="(item, i) in _diagnose.prescriptionList"
          :key="i+item.medicineId"
      >
        <template v-if="!isReadonly" #default>
          <div style="
            display: flex;
            flex-direction: row;
            justify-content: end;
            align-items: center;
            height: 100%;

">
            <div
                @click.stop="delMedicine(item.id, i)"
                style="margin-right: 6px"
            >
              <van-icon name="delete-o" color="#e14440" style="font-weight: bold"/>
              <span style="color: #e14440"> 删除</span>
            </div>
            <div
                @click.stop="editMedicine(item, i)"
            >
              <van-icon name="edit" color="#2a5fc0" style="font-weight: bold"/>
              <span style="color: #2a5fc0"> 编辑</span>
            </div>
          </div>

        </template>
      </van-cell>


      <van-empty
          v-if="isReadonly && !_diagnose.prescriptionList"
          description="未选择药品"
      />

      <!-- 添加按钮 -->
      <div v-if="!isReadonly" class="plus" @click="editMedicine()">
        <van-icon name="plus" color="var(--titleColor)" style="font-weight: bold"/>
      </div>

      <!-- 添加修改药品用量弹窗 -->
      <van-popup v-model="showPopup" class="addMedicineBox" round>
        <add-medicine
            :medicineInfo="medicineInfo"
            :medicineList="medicineList"
            @submit="saveMedicine"
            @close="showPopup = false"
        />
      </van-popup>
    </van-cell-group>

  </div>
</template>

<script>
import CheckoutGroup from "@/components/checkbox/CheckoutGroup.vue";
import {getMedicineList, saveDiagnosis, getDiagnosis, saveSleep} from "@/api/hoss";
import AddMedicine from "./AddMedicine";
import mixin from "../mixin.js";

export default {
  // mixins: [mixin],
  components: {AddMedicine, CheckoutGroup},
  props: {
    clientId: {
      type: String,
      default: "",
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    diagnose: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      timer: null,
      refName: "diagnose",
      textareaList: [
        {key: "doctorDiagnosis", label: "医院诊断结果：", placeholder: "多行输入"},
        {
          key: "extremeBehavior",
          label: "曾经是否有过极端行为：",
          placeholder: "多行输入",
        },
        {key: "medicalHistory", label: "有无其他病史：", placeholder: "多行输入"},
        {key: "autognosis", label: "对自身状况的认知：", placeholder: "多行输入"},
        {
          key: "bodyFeeling",
          label: "目前身体状况：",
          placeholder: "躯体疼痛、曾经的旧伤等",
        },
        {
          key: "socialState",
          label: "能否接触陌生人：",
          placeholder: "目前社交障碍、社恐，还是可以接触陌生人",
        },
      ],

      showPopup: false, // 药品用量弹窗
      medicineList: [], // 所有药品列表
      symptomsList: [], // 所有症状列表
      medicineInfo: {}, // 当前修改用量的药品信息

      symptoms: [],

      // 需要提交的数据
      formData: {
        doctorDiagnosis: "",
        extremeBehavior: "",
        medicalHistory: "",
        autognosis: "",
        bodyFeeling: "",
        socialState: "",
        clientId: this.clientId,
        prescriptionList: [],
        symptoms: "",
        deleteIds: [],
      },
    };
  },
  computed: {
    // 症状列表
    _diagnose: {
      get() {
        return this.diagnose
      },
      set(val) {
        this.$emit("update:report", val);
      }
    }
  },
  watch: {
    diagnose(val) {
      if (val) {
        if (val.symptoms) {
          this.symptoms = val.symptoms.split(",");
        } else {
          this.symptoms = [];
        }
      }
    },
  },
  filters: {
    medicineName(id, list) {
      if (!id || !list.length) return "";
      for (let i = 0; i < list.length; i++) {
        if (id == list[i].value) return list[i].text;
      }
    },
  },
  async created() {
    try {
      await Promise.all([this.getSymptoms(), this.getMedicineList()]);
      // if (this._diagnose.symptoms) {
      //   this.symptoms = this._diagnose.symptoms.split(",");
      // } else {
      //   this.symptoms = [];
      // }
    } catch (e) {
      this.$toast.clear();
      this.$toast.fail(e.message);
      console.log(e);
    }
  },


  methods: {
    onRefIsUploadingAddIntoList() {
      this.$emit("refIsUploadingAddIntoList", this.refName);
    },
    // 获取症状
    async getSymptoms() {
      const data = await this.$getDict();
      this.symptomsList = data.symptoms.children;
    },
    // 校验
    validator() {
      if (!this._diagnose.symptoms) {
        return false;
      }
      if (this._diagnose.symptoms.split(',').length === 1) {
        if (this._diagnose.symptoms.split(',')[0] === '') {
          return false;
        }
      }

      const {
        doctorDiagnosis,
        extremeBehavior,
        medicalHistory,
        autognosis,
        bodyFeeling,
        socialState,
      } = this._diagnose;
      if (
          !doctorDiagnosis ||
          !extremeBehavior ||
          !medicalHistory ||
          !autognosis ||
          !bodyFeeling ||
          !socialState
      ) {
        // this.$toast("请完善基本情况");
        return false;
      }
      return true;
    },

    // 获取所有药品列表
    async getMedicineList() {
      const {value} = await getMedicineList();
      this.medicineList = value.map((item) => {
        return {text: item.name || item.productName, value: item.id};
      });
    },

    // 药品的增删改
    delMedicine(id, i) {
      id && this._diagnose.deleteIds.push(id);
      this._diagnose.prescriptionList.splice(i, 1);
      this.$forceUpdate(); // 强制更新-无奈之举
    },
    editMedicine(item, index) {
      if (item) {
        this.medicineInfo = item;
        this.medicineInfo["index"] = index;
      } else {
        this.medicineInfo = {
          medicineId: "",
          timesPerDay: 0,
          dosePerDay: 0,
          id: "",
          index: -1,
        };
      }
      this.showPopup = true;
    },
    saveMedicine(e) {
      this.showPopup = false;
      const {index} = e;
      delete e.index;
      if (!this._diagnose.prescriptionList) {
        this._diagnose.prescriptionList = [];
      }
      if (index > -1) {
        this._diagnose.prescriptionList[index] = e;
      } else {
        this._diagnose.prescriptionList.push(e);
      }
    },
  },
};
</script>

<style scoped lang="less">
.form-box {
  padding-top: 0;
}

.tag-list {
  display: flex;
  padding: 10px 16px;
  flex-wrap: wrap;
}

.pb0 {
  padding-bottom: 0;
}

.pt0 {
  padding-top: 0;
}

.plus {
  height: 60px;
  margin: 10px 10px;
  line-height: 60px;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  background-color: #f1f6ff;
}

.addMedicineBox {
  background-color: var(--cardBackgroundColor);
  color: var(--titleColor);
  width: 80%;
}

.iconButton {
  vertical-align: middle;
  margin-left: 5px;
}

.van-cell-group {
  background-color: var(--cardBackgroundColor);
}

.basicInfo /deep/ .van-cell__value {
  background-color: var(--secondCardBackgroundColor);
  padding: 4px;
  border-radius: 4px;
}

.van-cell__title {
  color: var(--titleColor);
  font-size: 14px;
  letter-spacing: -0.4px;
}

/deep/ .van-field__value {
  padding: 3px 8px 5px;
  border-radius: 10px;
  margin-top: 6px;
  background-color: var(--separatorColor);
}

/deep/ .van-cell::after {
  display: none;
}

/deep/ .pb0 {
  padding-top: 0.3rem;
}

// textarea 占位文字

/deep/ textarea {
  font-size: 13px;
  // 修改占位符
  &::placeholder {
    color: var(--subTitleColor);
    font-weight: normal;
  }
}

/deep/ .van-field__word-limit {
  font-size: 13px;
  color: var(--subTitleColor);
}

/deep/ .van-field__word-num {
  font-size: 13px;
}

/deep/ .van-cell {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}

.van-cell--required::before {
  left: 0.1rem;
  color: #e14440;
}

.symptom-title::before {
  content: "*";
  color: #e14440;
  margin-right: -3px;
}

</style>
