<template>
  <div class="create-customer">
    <van-tabs v-model="active" ref="tabs" swipeable
              @change="onTabChange()"
              :lazy-render="false"
    >
      <van-tab title="基本信息"
               :title-class="
               validatorFailedList.includes(0)
               ? 'tab-title-error'
               : 'tab-title'"
      >
        <basic-info
            :is-readonly="isReadonly"
            @changeSaveStatus="changeHpTitleBarSaveComponentStatus"
            @tab-change="tabChange"
            ref="basicInfo"
            :basic-info.sync="clientFile.basicInfo"
        />
      </van-tab>

      <van-tab title="诊断情况"
               :title-class="
               validatorFailedList.includes(1)
               ? 'tab-title-error'
               : 'tab-title'">
        <diagnose
            :is-readonly="isReadonly"
            @tab-change="tabChange"
            @changeSaveStatus="changeHpTitleBarSaveComponentStatus"
            ref="diagnose"
            :diagnose.sync="clientFile.diagnosis"
        />
      </van-tab>

      <van-tab title="睡眠情况"
               :title-class="
               validatorFailedList.includes(2)
               ? 'tab-title-error'
               : 'tab-title'">
        <sleep
            :is-readonly="isReadonly"
            @tab-change="tabChange"
            @changeSaveStatus="changeHpTitleBarSaveComponentStatus"
            ref="sleep"
            :sleep.sync="clientFile.sleep"
        />
      </van-tab>

      <van-tab title="其他信息"
               :title-class="
               validatorFailedList.includes(3)
               ? 'tab-title-error'
               : 'tab-title'">
        <other
            :is-readonly="isReadonly"
            @tab-change="tabChange"
            @changeSaveStatus="changeHpTitleBarSaveComponentStatus"
            ref="other"
            :client-other.sync="clientFile.clientOther"
        />
      </van-tab>
    </van-tabs>

    <div class="hover-commit" v-if="!isReadonly" @click="onSubmit">
      提交
    </div>
  </div>
</template>

<script>
import BasicInfo from "./components/BasicInfo";
import Diagnose from "./components/Diagnose/index";
import Sleep from "./components/Sleep";
import Other from "./components/Other";
import {CloudSaveStatus} from "@/api/consts";
import {getClientFile, iceToOneStage, publishClientInfo, saveClientFile} from "@/api/hoss";
import {Dialog} from "vant";

export default {
  components: {BasicInfo, Diagnose, Sleep, Other},
  data() {
    return {
      active: 0,
      prevActive: 0,
      firstHaveClientId: false,
      isSubmitLeave: false, // 是否是提交离开,如果是提交离开，不需要监听返回逻辑
      // clientId: "",
      isNeedCreateClient: false,
      isReadonly: true,
      // 验证失败的列表
      validatorFailedList: [],
      // ClientId未获取到时，需要等待ClientId才能上传的组件
      waitClientIdToUploadList: [],
      // 正在等待上传的组件-比如timer被激活等待3秒时
      isUploadingList: [],
      clientFile: {
        basicInfo: {},
        diagnosis: {},
        sleep: {},
        clientOther: {},
      },
      lastSuccessClientFile: {},
      timer: null,
      loading: true,
      afterLoadingIsEdit: false,
      // firstLoading: true,
    };
  },
  computed: {
    clientId: {
      get() {
        return this.clientFile.basicInfo.id;
      },
      set(val) {
        this.clientFile.basicInfo.id = val;
      }
    }
  },
  watch: {
    active(newVal, oldVal) {
      this.prevActive = oldVal;
    },
    clientFile: {
      handler: function (val, oldVal) {
        // 三秒后自动保存
        if (this.loading) {
          return;
        }
        if (!this.afterLoadingIsEdit) {
          this.afterLoadingIsEdit = true;
        }
        if (!this.clientFile.ableToEdit) { // 查看详情时，不自动保存
          return;
        }
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          // 保存
          if (!this.isReadonly) {
            this.onSaveClientFile();
          }
        }, 3000);
      },
      deep: true
    }
  },
  created() {
    this.clientId = this.$route.query.clientId || "";
    this.isNeedCreateClient = this.$route.query.isNeedCreateClient || false;
    // 当clientId初始就有值时，说明是从客户列表进入的，此时需要获取客户信息，且不可编辑
    if (this.clientId) {
      this.isReadonly = true;
      this.firstHaveClientId = true;
      // clientFile
      getClientFile(this.clientId).then((res) => {
        this.clientFile = res.value;
      });
    } else {
      this.isReadonly = false;
    }
    this.changeHpTitleBarSaveComponentStatus(CloudSaveStatus.SUCCESS);
    // 延时50ms，设置loading为false，临时解决初始时van-field 将value修改触发timer的问题
    setTimeout(() => {
      this.loading = false;
      this.lastSuccessClientFile = JSON.parse(JSON.stringify(this.clientFile));
    }, 50);
  },
  mounted() {
    const tabs = this.$refs.tabs.$el;
    this.tabContents = tabs.querySelector(".van-tabs__content");
  },
  methods: {
    tabChange(val) {
      this.active += val;
      this.tabContents.scrollTop = 0;
    },
    fromActiveNumGetRefName(activeNum) {
      return {
        "0": "basicInfo",
        "1": "diagnose",
        "2": "sleep",
        "3": "other"
      }[activeNum];
    },
    onTabChange() {
      // 延时50ms
      setTimeout(() => {
        console.log("onTabChange", this.active, this.prevActive);
        // 判断prevActive，找到ref 0-basicInfo 1-diagnose 2-sleep 3-other
        const refName = this.fromActiveNumGetRefName(this.prevActive);
        console.log("refName", refName);
        const solveResult = (result) => {
          console.log("执行目标的validator方法-result", result);
          // 如果validator返回false，说明验证失败
          if (!result) {
            // 如果没有就添加
            if (!this.validatorFailedList.includes(this.prevActive)) {
              this.validatorFailedList.push(this.prevActive);
            }
          } else {
            // 如果validator返回true，说明验证成功
            if (this.validatorFailedList.includes(this.prevActive)) {
              this.validatorFailedList.splice(this.validatorFailedList.indexOf(this.prevActive), 1);
            }
          }
        };
        // 执行目标的validator方法
        var result = this.$refs[refName].validator();
        // 如果是promise，需要等待promise执行完毕
        if (result instanceof Promise) {
          result.then(res => {
            solveResult(res);
          });
        } else {
          solveResult(result);
        }
      }, 10);
    },
    async onSubmit() {
      console.log("onSubmit");
      // 遍历验证所有tab
      const refList = ["basicInfo", "diagnose", "sleep", "other"];
      const failedInfoList = [];
      const solveResult = (refName, result) => {
        console.log("执行目标的validator方法-result", result);
        // 如果validator返回false，说明验证失败
        const refName2index = {
          "basicInfo": 0,
          "diagnose": 1,
          "sleep": 2,
          "other": 3
        }[refName];
        if (!result) {
          // 如果没有就添加
          if (!this.validatorFailedList.includes(refName2index)) {
            this.validatorFailedList.push(refName2index);
          }
          switch (refName2index) {
            case 0:
              failedInfoList.push("基本信息");
              break;
            case 1:
              failedInfoList.push("诊断情况");
              break;
            case 2:
              failedInfoList.push("睡眠信息");
              break;
            case 3:
              failedInfoList.push("其他信息");
              break;
          }
        } else {
          // 如果validator返回true，说明验证成功
          if (this.validatorFailedList.includes(refName2index)) {
            this.validatorFailedList.splice(this.validatorFailedList.indexOf(refName2index), 1);
          }
        }
        console.log("this.validatorFailedList", this.validatorFailedList);
        console.log("failedInfoList", failedInfoList);
      };
      for (let i = 0; i < refList.length; i++) {
        const refName = refList[i];
        // 执行目标的validator方法
        console.log("refName", refName);
        var result = this.$refs[refName].validator();
        // 如果是promise，需要等待promise执行完毕
        if (result instanceof Promise) {
          result = await result;
        }
        solveResult(refName, result);
      }
      // 如果有验证失败的tab，提示用户
      if (failedInfoList.length > 0) {
        this.$toast(`请检查${failedInfoList.join("、")}是否填写完整`);
      } else {
        // 检查是否有refIsUploading
        // 检查是否有clientId
        const resultExecFuc = () => {
          // 如果没有clientId，说明是新增客户，需要保存客户信息
          this.isSubmitLeave = true;
          if (this.firstHaveClientId && !this.isNeedCreateClient) {
            // 返回上一页
            this.$router.go(-1);
          } else {
            // 执行保存
            publishClientInfo(this.clientId).then(() => {
              this.$toast("保存成功");
              this.$router.push(`/hoss/clientList?refresh=true`);
            }).catch(() => {
              this.$toast("保存失败");
            });
          }
        };
        this.onSaveClientFile(() => {
          resultExecFuc();
        });
      }
    },
    onSaveClientFile(
        onSuccess = () => {
        },
    ) {
      // 检查当前clientFile与lastSuccessClientFile是否一致，得出changedKeys
      const changedKeys = [];
      const clientFile = this.clientFile;
      const lastSuccessClientFile = this.lastSuccessClientFile;
      for (let key in clientFile) {
        // json
        if (key !== 'changedModules') {
          const currentDict = clientFile[key];
          const targetDict = lastSuccessClientFile[key];
          // 直接比较json
          if (JSON.stringify(currentDict) !== JSON.stringify(targetDict)) {
            changedKeys.push(key);
          }
        }
      }
      console.log("changedKeys", changedKeys);
      // 如果changedKeys为空，说明没有修改，不需要保存
      if (changedKeys.length === 0) {
        return;
      }
      // 得出changedKeys后，执行保存
      this.clientFile["changedModules"] = changedKeys;
      saveClientFile(this.clientFile).then((res) => {
        console.log("saveClientFile-res", res);
        this.lastSuccessClientFile = JSON.parse(JSON.stringify(this.clientFile));
        this.changeHpTitleBarSaveComponentStatus(CloudSaveStatus.SUCCESS);
        if (res.success) {
          this.clientId = res.value;
          onSuccess();
        }
      }).catch(() => {
        this.changeHpTitleBarSaveComponentStatus(CloudSaveStatus.ERROR);
      });
    },
    changeHpTitleBarSaveComponentStatus(status) {
      this.$emit("changeTitleBarComponent", {
            name: "CloudSaveStatusView",
            params: {
              status,
              isEdit: !this.isReadonly,
              type: "CloudSync",
              happenedTimeMs: Date.now()
            }
          }
      );
    },
    onTitleBarNeedTransferData(data) {
      console.log("onTitleBarNeedTransferData", data)
      if (data === 'changeToEdit') {
        this.isReadonly = false;
        this.$emit("changeTitleBarComponent", {
              name: "CloudSaveStatusView",
              params: {
                isEdit: true,
                status: CloudSaveStatus.NONE,
                type: "CloudSync",
                happenedTimeMs: Date.now()
              }
            }
        );
      } else if (data === 'needSave') {
        // this.$refs[this.fromActiveNumGetRefName(this.active)].beforeSave();
      }
    },
  },
  // 监听返回
  async beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave-this.isReadonly", this.isReadonly, "this.afterLoadingIsEdit",
        this.afterLoadingIsEdit, "this.clientFile.ableToEdit", this.clientFile.ableToEdit, "this.isSubmitLeave", this.isSubmitLeave);
    if (this.isReadonly) {
      next();
      return;
    }
    if (this.isSubmitLeave) {
      next();
      return;
    }
    // 如果是查看模式，询问他是否要保存
    if (this.afterLoadingIsEdit) {
      if (this.clientFile.clientStage.state !== 0) { // 说明是已发布的客户
        Dialog.confirm({
          title: "提示",
          message: "您有未保存的数据，是否保存？",
          className: "to-transfer-2-dialog",
          closeOnPopstate: false,
          closeOnClickOverlay: false,
        })
            .then(async () => {
              this.onSaveClientFile();
              next();
            })
            .catch(() => {
              /* on cancel */
              next();
            });
      } else {
        this.onSaveClientFile();
        next();
      }
    } else {-
      next();
    }
  },


};
</script>

<style lang="less" scoped>
/deep/ .van-tabs__content {
  height: calc(100vh - 70px - 44px);
  overflow-y: auto;
}

/deep/ .buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px 40px;

  .van-button {
    width: 80px;
  }
}

/deep/ .van-tabs__line {
  width: 1.8rem;
  height: 4px;
  border-radius: 0px;
}

/deep/ .van-tab--active {
  font-size: 15px;
  font-weight: bold;
}

/deep/ .van-tab {
  font-size: 15px;
}

/deep/ .tab-title-error {
  .van-tab__text {
    color: #e14440;
    font-weight: bold;
  }
}

.hover-commit {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 90%;
  margin-left: 5%;
  margin-bottom: 5px;
  border-radius: 30px;
  height: 44px;
  font-size: 15px;
  background-color: var(--mainColor);
  color: #FFFFFF;
  border-top: 1px solid #e5e5e5;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
