<template>
  <div class="sleep">
    <van-cell-group inset :border="false">
      <van-field label="一天累计睡眠时间：" label-width="auto" center required input-align="right">
        <template #input>
          <van-stepper
              v-model="_sleep.totalSleepHours"
              min="0"
              max="24"
              :disabled="isReadonly"
              integer
              style="margin-right: 10px"
          />
          小时
        </template>
      </van-field>

      <van-field label="早上醒来时心情如何：" label-width="auto" required :value="_sleep.feelingAfterSleep"
                 :rules="required">
        <template #input>
          <quick-select-edit-input :type="'text'" :form-data="_sleep" :target-key="'feelingAfterSleep'"
                                   :multiple="false"
                                   :align="'right'"
                                   :is-readonly="isReadonly"
                                   :show-edit-input="false" @changeForm="changeForm" :quick-select-options="[
            {name: '一般', targetValue: '一般'},
            {name: '很好', targetValue: '很好'},
            {name: '不好', targetValue: '不好'},
            {name: '不知道', targetValue: '不知道'},
          ]"/>
        </template>
      </van-field>

      <van-field label="一般几点入睡：" label-width="auto" center input-align="right">
        <template #input>
          <van-stepper
              v-model="_sleep.sleepTime"
              :disabled="isReadonly"
              min="0"
              max="23"
              integer
              style="margin-right: 10px"
          />
          点左右
        </template>
      </van-field>

      <van-field label="早醒后能否继续睡着：" label-width="auto">
        <template #input>
          <quick-select-edit-input :type="'text'" :form-data="_sleep" :target-key="'ableSleepAfterBreak'"
                                   :multiple="false"
                                   :align="'right'"
                                   :is-readonly="isReadonly"
                                   :show-edit-input="false" @changeForm="changeForm" :quick-select-options="[
            {name: '能', targetValue: true},
            {name: '不能', targetValue: false},
          ]"/>
        </template>
      </van-field>
    </van-cell-group>

  </div>
</template>

<script>
import {saveSleep, getSleep} from "@/api/hoss";
import mixin from "./mixin.js";
import QuickSelectEditInput from "@/views/hoss/client-record/components/QuickSelectEditInput.vue";

export default {
  components: {QuickSelectEditInput},
  // mixins: [mixin],
  props: {
    clientId: {
      type: String,
      default: "",
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    sleep: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      refName: "sleep",
      formData: {
        sleepTime: 10,
        ableSleepAfterBreak: "",
        totalSleepHours: 8,
        feelingAfterSleep: "一般",
        clientId: this.clientId,
        id: "",
      },
      required: [{required: true, message: "此项不可为空"}],
    };
  },
  async created() {
    if (this.clientId) {
      this.$toast.loading();
      await this.getSleep();
      this.$toast.clear();
    }
  },
  computed: {
    _sleep: {
      get() {
        return this.sleep;
      },
      set(value) {
        this.$emit("update:sleep", value);
      },
    }
  },
  methods: {
    onRefIsUploadingAddIntoList() {
      this.$emit("refIsUploadingAddIntoList", this.refName);
    },
    validator() {
      if (this._sleep.sleepTime === "" || this._sleep.sleepTime === null || this._sleep.sleepTime === undefined) {
        return false;
      }
      if (this._sleep.ableSleepAfterBreak === "" || this._sleep.ableSleepAfterBreak === null || this._sleep.ableSleepAfterBreak === undefined) {
        return false;
      }
      if (this._sleep.totalSleepHours === "" || this._sleep.totalSleepHours === null || this._sleep.totalSleepHours === undefined) {
        return false;
      }
      if (this._sleep.feelingAfterSleep === "" || this._sleep.feelingAfterSleep === null || this._sleep.feelingAfterSleep === undefined) {
        return false;
      }
      return true;
    },
    // async getSleep() {
    //   const {value} = await getSleep(this.clientId);
    //   this.setState(value);
    //   this.formData = {...this.formData, ...value};
    // },
    changeForm(newFormData) {
      this._sleep = {...this._sleep, ...newFormData};
    },
    async save() {
      await this.saveData();
    }
  },
};
</script>

<style scoped lang="less">
.van-radio:nth-child(1),
.van-radio:nth-child(2) {
  margin-bottom: 10px;
}

.sleep {
  margin-top: 10px;
}

/deep/ .van-stepper__minus, /deep/ .van-stepper__plus {
  background: transparent;
  width: 0.7rem;
  height: 0.7rem;
}

/deep/ .van-stepper__minus::before, /deep/ .van-stepper__plus::before, /deep/ .van-stepper__minus::after, /deep/ .van-stepper__plus::after {
  background-color: #000000;
}

/deep/ .van-stepper__input {
  width: 0.85rem;
  height: 0.7rem;
}
</style>
