<template>
  <van-form
      ref="myForm"
      label-width="90"
      validate-trigger="onSubmit"
      :show-error="false"
      :readonly="isReadonly"
      style="padding-bottom: 2rem"
  >
    <van-cell-group inset :border="false">
      <van-field
          v-model="_basicInfo.name"
          maxlength="300"
          label="姓名"
          placeholder="客户姓名"
      />

      <van-field
          v-model="_basicInfo.nickName"
          maxlength="300"
          label="昵称"
          placeholder="客户昵称"
          required
          :rules="[required]"
      />

      <van-field
          :value="_basicInfo.hpId ? `${_basicInfo.hpId} (${_basicInfo.hpUserName})` : ''"
          readonly
          maxlength="300"
          label="绑定会跑号"
          placeholder="客户会跑号"
          @click.native="!isReadonly && (searchHPPanel = true)"
      >
        <van-icon
            v-if="_basicInfo.hpId && !isReadonly"
            slot="right-icon"
            name="clear"
            @click.native.stop="_basicInfo.hpId = ''"
        />
      </van-field>

      <van-field label="性别" required :rules="[required]" :value="_basicInfo.gender">
        <template #input>
          <quick-select-edit-input :type="'text'"
                                   :form-data="_basicInfo"
                                   :target-key="'gender'"
                                   :multiple="false"
                                   :is-readonly="isReadonly"
                                   :show-edit-input="false" @changeForm="changeForm" :quick-select-options="[
              {
                name: '男',
                targetValue: 1
              },
              {
                name: '女',
                targetValue: 0
              }
          ]"/>
        </template>
      </van-field>

      <van-field
          v-model="_basicInfo.nationality"
          maxlength="300"
          label="民族"
          placeholder="客户民族"
      >
        <template #input>
          <quick-select-edit-input :type="'text'"
                                   :form-data="_basicInfo"
                                   :target-key="'nationality'"
                                   :multiple="false"
                                   :is-readonly="isReadonly"
                                   :placeholder="'手动输入或选择下列常用民族'"
                                   :show-edit-input="true"
                                   @changeForm="changeForm"
                                   :quick-select-options="[
              {
                name: '汉族',
                targetValue: '汉族'
              },
              {
                name: '满族',
                targetValue: '满族'
              },
              {
                name: '回族',
                targetValue: '回族'
              }
          ]"/>
        </template>
      </van-field>

      <van-field
          v-model="_basicInfo.nativePlace"
          maxlength="300"
          label="籍贯"
          placeholder="客户籍贯"
      />

      <van-field
          v-model="_basicInfo.heightInCm"
          maxlength="300"
          label="身高（CM）"
          type="digit"
          placeholder="客户身高"
          required
          :rules="[required]"
      />

      <van-field
          v-model="_basicInfo.weightInKg"
          maxlength="300"
          label="体重（KG）"
          type="number"
          placeholder="客户体重"
          required
          :rules="[required, weightRules]"
      />

      <van-field
          readonly
          required
          clickable
          label="出生日期"
          placeholder="点击选择出生日期"
          :value="timeFormat(_basicInfo.birthday)"
          :rules="[required]"
          @click="!isReadonly && (birthdayPopup = true)"
      />

      <van-field v-model="_basicInfo.age" label="年龄" readonly placeholder="客户年龄"/>

      <van-field
          readonly
          clickable
          label="教育程度"
          placeholder="点击进行选择"
          required
          :value="_basicInfo.education"
          :rules="[required]"
      >
        <template #input>
          <quick-select-edit-input :type="'text'"
                                   :form-data="_basicInfo"
                                   :target-key="'education'"
                                   :is-readonly="isReadonly"
                                   :multiple="false"
                                   :show-edit-input="false"
                                   @changeForm="changeForm"
                                   :quick-select-options="[
              {
                name: '小学',
                targetValue: '小学'
              },
              {
                name: '初中',
                targetValue: '初中'
              },
              {
                name: '中专',
                targetValue: '中专'
              },
              {
                name: '高中',
                targetValue: '高中'
              },
              {
                name: '专科',
                targetValue: '专科'
              },
              {
                name: '本科',
                targetValue: '本科'
              },
              {
                name: '硕士',
                targetValue: '硕士'
              },
              {
                name: '博士',
                targetValue: '博士'
              },
              {
                name: '其他',
                targetValue: '其他'
              }
          ]"/>
        </template>
      </van-field>

      <van-field
          readonly
          clickable
          label="在职状态"
          placeholder="点击进行选择"
          required
          :value="_basicInfo.onJob"
          :rules="[required]"
      >
        <template #input>
          <quick-select-edit-input :type="'text'" :form-data="_basicInfo" :target-key="'onJob'"
                                   :is-readonly="isReadonly"
                                   :multiple="false"
                                   :show-edit-input="false" @changeForm="changeForm" :quick-select-options="[
              {
                name: '学生',
                targetValue: '学生'
              },
              {
                name: '休学中',
                targetValue: '休学中'
              },
              {
                name: '在职',
                targetValue: '在职'
              },
              {
                name: '离职',
                targetValue: '离职'
              },
              {
                name: '退休',
                targetValue: '退休'
              },
              {
                name: '其他',
                targetValue: '其他'
              }
          ]"/>
        </template>
      </van-field>

      <van-field
          v-model="_basicInfo.phone"
          maxlength="300"
          type="tel"
          label="联系电话"
          placeholder="客户联系电话"
      />

      <van-field v-model="_basicInfo.allergySource" maxlength="300" placeholder="客户过敏源" label="过敏源"/>

      <van-field
          readonly
          clickable
          label="家庭住址"
          placeholder="选择省 / 市 / 区"
          :value="addressFormat(_basicInfo.province, _basicInfo.city, _basicInfo.district)"
          @click="showAreaPopup('home')"
      />
      <van-field
          v-model="_basicInfo.address"
          maxlength="300"
          label="详细住址"
          placeholder="街道门牌、楼层房间号等信息"
      />

      <template v-if="_basicInfo.onJob == '学生'">
        <van-field
            readonly
            clickable
            label="学校地址"
            placeholder="选择省 / 市 / 区"
            :value="
                        addressFormat(
                            _basicInfo.schoolProvince,
                            _basicInfo.schoolCity,
                            _basicInfo.schoolDistrict
                        )
                    "
            @click="showAreaPopup('school')"
        />
        <van-field
            v-model="_basicInfo.schoolAddress"
            maxlength="300"
            label="详细地址"
            placeholder="学校详细地址"
        />
      </template>

      <van-field
          v-model="_basicInfo.contractId"
          maxlength="300"
          label="合同编号"
          placeholder="合同编号"
      />
    </van-cell-group>

    <!-- 弹窗 -->
    <!-- 选择日期时间 -->
    <van-popup v-model="birthdayPopup" round position="bottom">
      <van-datetime-picker
          :value="_basicInfo.birthday ? new Date(_basicInfo.birthday) : new Date(2000, 0, 1)"
          type="date"
          :min-date="minDate"
          :max-date="maxDate"
          @cancel="birthdayPopup = false"
          @confirm="saveBirthday"
      />
    </van-popup>

    <!-- 单列选择器 -->
    <van-popup v-model="pickerPopup" round position="bottom">
      <van-picker
          show-toolbar
          :columns="columns"
          :defaultIndex="defaultIndex"
          @cancel="pickerPopup = false"
          @confirm="saveEducation"
      />
    </van-popup>

    <!-- 地址选择器 -->
    <van-popup v-model="areaPopup" round position="bottom">
      <van-area
          ref="areaPicker"
          :area-list="areaList"
          @confirm="saveAddress"
          @cancel="areaPopup = false"
          :columns-placeholder="['请选择', '请选择', '请选择']"
      />
    </van-popup>

    <!-- 选择会跑用户 -->
    <van-popup v-model="searchHPPanel" position="bottom" style="height: 80%">
      <van-search
          v-model="searchVal"
          placeholder="输入会跑号或手机号进行搜索"
          @search="onSearch"
      />
      <template v-if="searchList.length">
        <van-cell v-for="item in searchList" :key="item.id" @click="checked(item)">
          {{ item.hpId }} ({{ item.nickname }})
          <p class="phone" v-if="item.phone" style="margin: 0">
            手机号：{{ item.phone }}
          </p>
        </van-cell>
      </template>
      <van-empty v-else description="暂无数据"/>
    </van-popup>
  </van-form>
</template>

<script>
import {areaList} from "@vant/area-data";
import {saveClientInfo, getClientInfo} from "@/api/hoss";
import {getHPUserList} from "@/api/common";
import {getAge, timeFormat} from "@/utils";
import mixin from "./mixin.js";
import {CloudSaveStatus} from "@/api/consts";
import QuickSelectEditInput from "@/views/hoss/client-record/components/QuickSelectEditInput.vue";
import dayjs from "dayjs";

export default {
  components: {QuickSelectEditInput},
  // mixins: [mixin],
  props: {
    clientId: {
      type: String,
      default: "",
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    basicInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      state: 0,
      refName: "basicInfo",
      // 校验规则
      required: {required: true, message: "此项不可为空"},
      weightRules: {pattern: /^(\d+)(.\d{1,2})?$/, message: "请正确填写体重"},

      // 选择器相关
      birthdayPopup: false,
      minDate: new Date(new Date().getFullYear() - 100, 0, 1),
      maxDate: new Date(new Date().toLocaleDateString()),
      pickerPopup: false,
      defaultIndex: 0,
      columns: [],
      areaPopup: false,
      areaList,
      searchHPPanel: false,
      searchVal: "",
      searchList: [],
      initForm: null,
      // 表单数据
      formData: {
        id: "",
        name: "",
        nickName: "",
        hpId: "",
        hpUserName: "",
        hpUserId: "",
        gender: null,
        nationality: "",
        nativePlace: "",
        heightInCm: "",
        weightInKg: "",
        birthday: "",
        age: "",
        education: "",
        onJob: "",
        phone: "",
        allergySource: "",
        province: "",
        city: "",
        district: "",
        address: "",
        schoolProvince: "",
        schoolCity: "",
        schoolDistrict: "",
        schoolAddress: "",
      },
      lastAutoSaveTimeMs: null,
      // 定时器
      timer: null,
    };
  },

  computed: {
    _basicInfo: {
      get() {
        console.log("basicInfo", this.basicInfo);
        return this.basicInfo;
      },
      set(val) {
        this.$emit("update:basicInfo", val);
      },
    },
  },

  watch: {
    "_basicInfo.birthday": {
      immediate: true,
      handler(birthday) {
        birthday ? (this._basicInfo.age = getAge(birthday)[0]) : "";
      },
    },
  },

  async created() {
    // console.log("basicInfo初始化：isReadonly", this.isReadonly);
    // this.$toast.loading();
    // await this.initFormData();
    // this.$toast.clear();
  },

  methods: {
    timeFormat,
    addressFormat(province, city, district) {
      return city && district ? `${province}/${city}/${district}` : "";
    },
    // async save() {
    //   if (!this.formData.nickName) {
    //     // 自动保存 2023/2/15 12:53:22
    //     // this.formData.nickName = "自动保存" + dayjs().format("YYYY/MM/DD HH:mm:ss");
    //     await this.beforeSave();
    //   } else {
    //     await this.beforeSave();
    //   }
    // },
    // 保存
    // async saveData() {
    //   if (this._basicInfo.onJob !== "学生") {
    //     this.schoolProvince = "";
    //     this.schoolCity = "";
    //     this.schoolDistrict = "";
    //     this.schoolAddress = "";
    //   }
    //   const {value} = await saveClientInfo(this._basicInfo);
    //
    //   // 如果是新建的话 需要更新当前表单的id，页面的clientId
    //   if (this._basicInfo.id === "") {
    //     this._basicInfo.id = value.clientId;
    //   }
    //   this.$emit("update:clientId", value.clientId);
    //   this.$emit("onSaveSuccess", "basicInfo");
    // },
    onRefIsUploadingAddIntoList() {
      this.$emit("refIsUploadingAddIntoList", this.refName);
    },
    async validator() {
      try {
        await this.$refs.myForm.validate();
        return true;
      } catch (error) {
        // this.$toast("请检查填写项");
        return false;
      }
    },

    // 弹窗确认按钮
    saveBirthday(time) {
      this._basicInfo.birthday = time.getTime();
      this.birthdayPopup = false;
    },
    saveAddress(list) {
      for (let i = 0; i < list.length; i++) {
        if (!list[i] || !list[i].name) return this.$toast("请选择地区");
      }
      const [{name: province}, {name: city}, {name: district}] = list;
      if (this.areaType == "home") {
        this._basicInfo.province = province;
        this._basicInfo.city = city;
        this._basicInfo.district = district;
      } else {
        this._basicInfo.schoolProvince = province;
        this._basicInfo.schoolCity = city;
        this._basicInfo.schoolDistrict = district;
      }
      this.areaPopup = false;
    },
    saveEducation(val) {
      this.pickerPopup = false;
      this._basicInfo[this.pickerType] = val;
    },

    // 查看单列选择器
    showPicker(val) {
      if (this.isReadonly) return;
      this.pickerType = val;
      this.columns = {
        education: ["小学", "初中", "中专", "高中", "专科", "本科", "硕士", "博士", "其他"],
        onJob: ["学生", "休学中", "在职", "离职", "退休", "其他"],
      }[val];
      const defaultIndex = this.columns.indexOf(this._basicInfo[val]);
      this.defaultIndex = defaultIndex > 0 ? defaultIndex : 0;
      this.pickerPopup = true;
    },
    // 打开省市区选择器
    showAreaPopup(type) {
      if (this.isReadonly) return;
      this.$refs.areaPicker?.reset();
      this.areaType = type;
      this.areaPopup = true;
    },

    // async initFormData() {
    //   if (this.clientId) {
    //     this.$toast.loading();
    //     const {value} = await getClientInfo(this.clientId);
    //     this.setState(value);
    //     this.formData = {...this.formData, ...value};
    //     this.$toast.clear();
    //   }
    // },

    async onSearch() {
      if (!this.searchVal) return (this.searchList = []);
      if (!/^[+]{0,1}(\d+)$/.test(this.searchVal)) return this.$toast("请输入正确会跑号");
      const {value} = await getHPUserList(this.searchVal);
      this.searchList = value;
    },
    checked(e) {
      this._basicInfo.hpId = e.hpId;
      this._basicInfo.hpUserName = e.nickname;
      this._basicInfo.hpUserId = e.id;
      this.searchHPPanel = false;
    },
    // changeForm
    changeForm(newFormData) {
      this._basicInfo = {...this._basicInfo, ...newFormData};
    },
  },
};
</script>

<style scoped lang="less">
.van-form {
  margin-top: 10px;
}

.phone {
  font-size: 13px;
  color: var(--subTitleColor);
}

/deep/ .van-cell__title {
  color: var(--titleColor);
  font-size: 14px;
  letter-spacing: -0.4px;
}

/deep/ .van-field__body {
  font-size: 14px;
}
</style>
